import React from "react";

import { AppBar, Button, Typography } from "@mui/material";
import CAREGEM_LOGO from "images/caregem-logo.png";
import I18nKey from "lib/I18nKeys";
import { FormattedMessage } from "react-intl";
import "./SuperAdminAppbar.css";

interface SuperAdminAppbarProps {
  readonly username: string;
  readonly logoutUser: () => void;
  readonly children: JSX.Element;
}

export const SuperAdminAppbar: React.FC<SuperAdminAppbarProps> = ({
  children,
  username,
  logoutUser,
}) => {
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#90caf9",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div className="appBarTitle">
          <div>
            <Typography variant="h6" noWrap>
              <img
                alt=""
                src={CAREGEM_LOGO}
                width="110"
                height="40"
                className="logo-image"
              />
              <FormattedMessage id={I18nKey.ADMIN_PORTAL_VERSION_LABEL} />
            </Typography>
          </div>
          <div>
            <div className="white textCenter">{username}</div>
            <Button color="inherit" onClick={logoutUser}>
              {<FormattedMessage id={I18nKey.LOGOUT} />}
            </Button>
          </div>
        </div>
      </AppBar>
      <div className="pageContainer">{children}</div>
    </>
  );
};
