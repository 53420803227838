import includes from "lodash/includes";
import values from "lodash/values";

enum I18nKey {
  USER_FORM_FIELD_NAME = "react.user.form.field.name",
  USER_FORM_FIELD_USERNAME = "react.user.form.field.username",
  USER_FORM_FIELD_PASSWORD = "react.user.form.field.password",
  USER_FORM_FIELD_NEW_PASSWORD = "react.user.form.field.newPassword",
  USER_FORM_FIELD_CONFIRM_PASSWORD = "react.user.form.field.confirmPassword",
  USER_FORM_FIELD_FIRSTNAME = "react.user.form.field.firstname",
  USER_FORM_FIELD_LASTNAME = "react.user.form.field.lastname",
  USER_FORM_FIELD_EMAIL = "react.user.form.field.email",
  USER_FORM_FIELD_DATE_OF_BIRTH = "react.user.form.field.dateofbirth",
  USER_FORM_FIELD_GENDER = "react.user.form.field.gender",
  USER_FORM_FIELD_SEX = "react.user.form.field.sex",
  USER_FORM_FIELD_TELEPHONE = "react.user.form.field.telephone",
  USER_FORM_FIELD_WORK_PHONE = "react.user.form.field.workTel",
  USER_FORM_FIELD_MOBILE_NUMBER = "react.user.form.field.mobile_number",
  USER_FORM_FIELD_CELL = "react.user.form.field.cell",
  USER_FORM_FIELD_PHONE_1_COUNTRY_CODE = "react.user.form.field.phone1CountryCode",
  USER_FORM_FIELD_PHONE_2_COUNTRY_CODE = "react.user.form.field.phone2CountryCode",
  USER_FORM_FIELD_WORK_PHONE_COUNTRY_CODE = "react.user.form.field.workPhoneCountryCode",
  USER_FORM_FIELD_CELL_COUNTRY_CODE = "react.user.form.field.cellCountryCode",
  USER_FORM_FIELD_HOME_ADDRESS_1 = "react.user.form.field.home_address_1",
  USER_FORM_FIELD_HOME_ADDRESS_2 = "react.user.form.field.home_address_2",
  USER_FORM_FIELD_OFFICE_ADDRESS_1 = "react.user.form.field.office_address_1",
  USER_FORM_FIELD_OFFICE_ADDRESS_2 = "react.user.form.field.office_address_2",
  USER_FORM_FIELD_WORK_ADDRESS_1 = "react.user.form.field.work_addr_1",
  USER_FORM_FIELD_WORK_ADDRESS_2 = "react.user.form.field.work_addr_2",
  USER_FORM_FIELD_WORK_ADDRESS_CITY = "react.user.form.field.work_address_city",
  USER_FORM_FIELD_ADDRESS_CITY = "react.user.form.field.address_city",
  USER_FORM_FIELD_STATE = "react.user.form.field.state",
  USER_FORM_FIELD_ZIP_CODE = "react.user.form.field.zipcode",
  USER_FORM_FIELD_SSN = "react.user.form.field.ssn",
  USER_FORM_FIELD_LICENSE_NUMBER = "react.user.form.field.license_number",
  USER_FORM_FIELD_DEVICE_PAIRED = "react.user.form.field.device_paired",
  USER_FORM_FIELD_REF_UID = "react.user.form.field.ref_uid",
  USER_FORM_FIELD_SPECIALITY = "react.user.form.field.speciality",
  USER_FORM_FIELD_GROUP_NAME = "react.user.form.field.group_name",
  USER_FORM_FIELD_NURSING_LICENSE_NUMBER = "react.user.form.field.nursing_license_number",
  USER_FORM_FIELD_GRADUATION_YEAR = "react.user.form.field.graduation_year",
  USER_FORM_FIELD_DEA_NUMBER = "react.user.form.field.dea_number",
  USER_FORM_FIELD_NPI = "react.user.form.field.npi",
  USER_FORM_FIELD_DEGREE = "react.user.form.field.degree",
  USER_FORM_FIELD_PHONE_NUMBER_1 = "react.user.form.field.phone1",
  USER_FORM_FIELD_PHONE_NUMBER_2 = "react.user.form.field.phone2",
  USER_FORM_FIELD_ADDRESS = "react.user.form.field.address",
  USER_FORM_FIELD_ADMIN_READ_ONLY = "react.user.form.field.adminReadOnly",
  USER_FORM_FIELD_START_DATE = "react.user.form.field.start_date",
  USER_FORM_FIELD_END_DATE = "react.user.form.field.end_date",
  USER_FORM_FIELD_DEVICE_ASSIGNMENT = "react.user.form.field.device_assignment",
  USER_FORM_FIELD_DEVICE_PROVIDER = "react.user.form.field.device_provider",
  USER_FORM_FIELD_PROVIDER_DATA = "react.user.form.field.provider_data",
  USER_FORM_FIELD_REMINDER_DAYS_FREQUENCY = "react.user.form.field.reminder_days_frequency",
  USER_FORM_FIELD_REMINDER_WINDOW = "react.user.form.field.reminder_window",

  ERROR_MESSAGE = "react.error",
  INFO = "info",
  WARNING = "warning",
  OK = "ok",

  EXCEPTION_DIALOG_BUTTON_CREATE_NEW = "react.exceptionDialog.button.createNew",

  SUPER_ADMIN_LOGIN_TITLE = "superAdmin.login.title",
  SUPER_ADMIN_LOGIN_BUTTON_LABEL = "superAdmin.login.button.label",

  ADMIN_PORTAL_VERSION_LABEL = "admin.portal.version.label",

  RESTORE_USERS = "restoreUsers",

  ADD_ORGANIZATION = "addOrg",
  ADD_CUST_ADMIN = "custAdmin.add",
  UPDATE_CUST_ADMIN = "custAdmin.update",

  ORG_UPDATE_SUCESSFUL = "superAdmin.org.update.successful.message",
  ORG_UPDATE_FAILED = "superAdmin.org.update.failed.message",
  ORG_ADDITION_SUCESSFUL = "superAdmin.org.addition.successful.message",
  ORG_ADDITION_FAILED = "superAdmin.org.addition.failed.message",

  CUST_ADMIN_TITLE_TEXT = "superAdmin.custAdmin.title",

  CUST_ADMIN_DELETE_CONFIRMATION_TITLE = "custAdmin.delete.confirmation.title",
  CUST_ADMIN_DELETE_CONFIRMATION_CONTENT = "custAdmin.delete.confirmation.content",

  CUST_ADMIN_ADDITION_SUCCESSFUL = "custAdmin.addition.successful.message",
  CUST_ADMIN_ADDITION_FAILED = "custAdmin.addition.failed.message",

  CUST_ADMIN_DELETION_SUCCESSFUL = "custAdmin.delete.successful.message",
  CUST_ADMIN_DELETION_FAILED = "custAdmin.delete.failed.message",

  CUST_ADMIN_UPDATE_SUCCESSFUL = "custAdmin.update.successful.message",
  CUST_ADMIN_UPDATE_FAILED = "custAdmin.update.failed.message",

  SUPER_ADMIN_ACCESS_DENIED = "superAdmin.accessDenied",
  SUPER_ADMIN_WRONG_DETAILS = "superAdmin.wrong.details",
  SUPER_ADMIN_PASSWORDS_DONT_MATCH = "superAdmin.password.not.matched",
  ADD_NEW = "addNew",

  CREATE = "create",

  SIGN_IN = "signin",

  LOGOUT = "logout",
  UPDATE = "update",
  CANCEL = "cancel",

  CONFIRM = "confirm",
}

export const isI18Key = (value: I18nKey | string): value is I18nKey =>
  includes(values(I18nKey), value);

export default I18nKey;
