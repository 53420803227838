import React, { useContext, useEffect, useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { createApiClient } from "api/apiClient";
import { UserContextState, UserContext } from "contexts/userContext";
import logo from "images/caregem-logo.png";
import I18nKey from "lib/I18nKeys";
import { useIntl } from "react-intl";
import UserPool from "UserPool";
import "./SuperAdminRoute.css";

interface SuperAdminLoginProps {
  readonly setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

enum InputType {
  text = "text",
  password = "password",
}

const VISIBILITY_ICON_SIZE = "small";

const SuperAdminLogin: React.FC<SuperAdminLoginProps> = ({
  setIsAuthenticated,
}) => {
  const intl = useIntl();
  const userContext = useContext<UserContextState>(UserContext);
  const [userName, setuserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewPasswordRequired, setIsNewPasswordRequired] =
    useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const onShowPasswordChange = () => setShowPassword(!showPassword);

  const onShowConfirmPasswordChange = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (errorMessage) {
      setErrorMessage("");
    }
    const user = new CognitoUser({
      Username: userName,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: userName,
      Password: password,
    });

    setIsLoading(true);

    if (isNewPasswordRequired) {
      cognitoUser?.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: (user) => {
            const accessToken = user.getAccessToken().getJwtToken();

            localStorage.setItem("super_admin_access_token", accessToken);
            createApiClient(accessToken)
              .getLoggedInUserData()
              .then((userData) => {
                userContext.setLoggedInUserData(userData);
                if (userData.role === "super_admin") {
                  setIsLoading(false);
                  setIsAuthenticated(true);
                  return;
                }
                setIsLoading(false);
                setIsAuthenticated(false);
                setErrorMessage(
                  intl.formatMessage({
                    id: I18nKey.SUPER_ADMIN_ACCESS_DENIED,
                  })
                );
              })
              .catch(() => {
                setIsLoading(false);
                setIsAuthenticated(false);
                setErrorMessage("Failed to load user information");
              });
          },
          onFailure: () => {
            setIsLoading(false);
            setErrorMessage(intl.formatMessage({ id: I18nKey.ERROR_MESSAGE }));
          },
        }
      );
    } else {
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          const accessToken = data.getAccessToken().getJwtToken();
          localStorage.setItem("super_admin_access_token", accessToken);
          createApiClient(accessToken)
            .getLoggedInUserData()
            .then((userData) => {
              userContext.setLoggedInUserData(userData);
              if (userData.role === "super_admin") {
                setIsLoading(false);
                setIsAuthenticated(true);
                return;
              }
              setIsLoading(false);
              setIsAuthenticated(false);
              setErrorMessage(
                intl.formatMessage({ id: I18nKey.SUPER_ADMIN_ACCESS_DENIED })
              );
            })
            .catch(() => {
              setIsLoading(false);
              setIsAuthenticated(false);
              setErrorMessage("Failed to load user information");
            });
        },
        onFailure: (err) => {
          console.error("onFailure: ", err);
          setIsLoading(false);
          setErrorMessage(
            intl.formatMessage({ id: I18nKey.SUPER_ADMIN_WRONG_DETAILS })
          );
        },
        newPasswordRequired: () => {
          setIsLoading(false);
          setIsNewPasswordRequired(true);
        },
      });
      setCognitoUser(user);
    }
  };

  useEffect(() => {
    if (!newPassword || !confirmNewPassword) return;
    if (newPassword !== confirmNewPassword) {
      setErrorMessage(
        intl.formatMessage({ id: I18nKey.SUPER_ADMIN_PASSWORDS_DONT_MATCH })
      );
    } else {
      if (
        (newPassword.length < 8 || newPassword.length > 25) &&
        (confirmNewPassword.length < 8 || confirmNewPassword.length > 25)
      ) {
        setErrorMessage("Password length needs to be between 8-25 characters");
      } else {
        setErrorMessage("");
      }
    }
  }, [newPassword, confirmNewPassword, intl]);

  useEffect(() => {
    if (!password) return;
    if (password.length < 8 || password.length > 25) {
      setErrorMessage("Password length needs to be between 8-25 characters");
    } else {
      setErrorMessage("");
    }
  }, [password]);

  const isSubmitActive = () =>
    newPassword && confirmNewPassword ? false : true;

  return (
    <div className="pageCenter">
      <div>
        <img src={logo} alt="Caregem-logo" className="caregem-logo" />
      </div>
      <div>{intl.formatMessage({ id: I18nKey.SUPER_ADMIN_LOGIN_TITLE })}</div>
      <form
        onSubmit={onSubmit}
        className="col-md-6 col-md-offset-3 m-auto textCenter"
        noValidate
        autoComplete="off"
      >
        <Box
          sx={{
            "& .MuiTextField-root": {
              m: 1,
              width: "30ch",
            },
          }}
        >
          <TextField
            fullWidth
            variant="standard"
            type={
              isNewPasswordRequired
                ? showPassword
                  ? InputType.text
                  : InputType.password
                : InputType.text
            }
            value={isNewPasswordRequired ? newPassword : userName}
            required
            autoComplete="on"
            placeholder={
              isNewPasswordRequired
                ? intl.formatMessage({
                    id: I18nKey.USER_FORM_FIELD_NEW_PASSWORD,
                  })
                : intl.formatMessage({ id: I18nKey.USER_FORM_FIELD_USERNAME })
            }
            name={isNewPasswordRequired ? "newPassword" : "username"}
            onChange={(e) =>
              isNewPasswordRequired
                ? setNewPassword(e.target.value)
                : setuserName(e.target.value)
            }
            InputProps={
              isNewPasswordRequired
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={onShowPasswordChange}
                        >
                          {showPassword ? (
                            <Visibility fontSize={VISIBILITY_ICON_SIZE} />
                          ) : (
                            <VisibilityOff fontSize={VISIBILITY_ICON_SIZE} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
          />
          <br />
          <TextField
            fullWidth
            variant="standard"
            type={showConfirmPassword ? InputType.text : InputType.password}
            value={isNewPasswordRequired ? confirmNewPassword : password}
            required
            autoComplete="on"
            name={isNewPasswordRequired ? "confirmPassword" : "password"}
            placeholder={
              isNewPasswordRequired
                ? intl.formatMessage({
                    id: I18nKey.USER_FORM_FIELD_CONFIRM_PASSWORD,
                  })
                : intl.formatMessage({ id: I18nKey.USER_FORM_FIELD_PASSWORD })
            }
            onChange={(e) =>
              isNewPasswordRequired
                ? setConfirmNewPassword(e.target.value)
                : setPassword(e.target.value)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={onShowConfirmPasswordChange}
                  >
                    {showConfirmPassword ? (
                      <Visibility fontSize={VISIBILITY_ICON_SIZE} />
                    ) : (
                      <VisibilityOff fontSize={VISIBILITY_ICON_SIZE} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className="red">{errorMessage}</div>
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="inherit"
          sx={{ backgroundColor: "white" }}
          disabled={isNewPasswordRequired ? isSubmitActive() : isLoading}
        >
          {isLoading ? (
            <CircularProgress size={30} />
          ) : (
            `${intl.formatMessage({
              id: I18nKey.SIGN_IN,
            })}`
          )}
        </Button>
      </form>
    </div>
  );
};
export default SuperAdminLogin;
