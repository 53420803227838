import React, { useEffect, useState } from "react";

import Delete from "@mui/icons-material/Delete";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { GetSuperAdminOrgCustomerAdminData } from "api/SharedTypes";
import I18nKey from "lib/I18nKeys";
import { useIntl } from "react-intl";
import "./SuperAdminUpdateDialog.css";

interface CustomerAdminProps {
  readonly custAdmins: GetSuperAdminOrgCustomerAdminData[];
  readonly getCustAdminData: (orgId: number, adminId: number) => void;
  readonly handleAddCustAdminDialogOpen: () => void;
  readonly deleteCustAdmin: (orgId: number, adminId: number) => void;
  readonly isCustomerAdminDataLoading: boolean;
  readonly isCustAdminDataError: boolean;
  readonly isDeletingCustAdmin: boolean;
}

export const CustomerAdmin: React.FC<CustomerAdminProps> = ({
  custAdmins,
  getCustAdminData,
  handleAddCustAdminDialogOpen,
  deleteCustAdmin,
  isCustAdminDataError,
  isCustomerAdminDataLoading,
  isDeletingCustAdmin,
}) => {
  const intl = useIntl();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [selectedCustId, setSelectedCustId] = useState<string>("");
  const [selectedCustOrgId, setSelectedCustOrgId] = useState<string>("");

  const handleDeleteDialogOpen = (orgId: string, adminId: string) => {
    setIsDeleteDialogOpen(true);
    setSelectedCustOrgId(orgId);
    setSelectedCustId(adminId);
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
    setSelectedCustId("");
  };

  const handleCustAdminDelete = () => {
    deleteCustAdmin(Number(selectedCustOrgId), Number(selectedCustId));
  };

  useEffect(() => {
    if (!isDeletingCustAdmin) {
      handleDeleteDialogClose();
    }
  }, [isDeletingCustAdmin]);

  return isCustomerAdminDataLoading ? (
    <div className="centerLoading">
      <CircularProgress />
    </div>
  ) : isCustAdminDataError ? (
    <div className="centerLoading ">
      <div className="red">Failed to fetch Customer Admin List</div>
    </div>
  ) : (
    <List>
      <div className="custAdminTitleText">
        {intl.formatMessage({ id: I18nKey.CUST_ADMIN_TITLE_TEXT })}
      </div>
      {custAdmins?.map((admin) => (
        <ListItem
          key={admin.id}
          disablePadding
          sx={{ paddingLeft: 3, paddingRight: 3 }}
        >
          <ListItemButton
            onClick={() =>
              getCustAdminData(Number(admin.org_id), Number(admin.id))
            }
          >
            <ListItemText primary={admin.name} sx={{ color: "#106a9c" }} />
          </ListItemButton>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => handleDeleteDialogOpen(admin.org_id, admin.id)}
          >
            <Delete fontSize="medium" />
          </IconButton>
        </ListItem>
      ))}
      <div className="centerLoading">
        <Button
          variant="contained"
          onClick={handleAddCustAdminDialogOpen}
          color="inherit"
          sx={{ backgroundColor: "white", color: "#106a9c" }}
        >
          {intl.formatMessage({ id: I18nKey.ADD_NEW })}
        </Button>
      </div>
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>
          {intl.formatMessage({
            id: I18nKey.CUST_ADMIN_DELETE_CONFIRMATION_TITLE,
          })}
        </DialogTitle>
        <DialogContent>
          {intl.formatMessage({
            id: I18nKey.CUST_ADMIN_DELETE_CONFIRMATION_CONTENT,
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>
            {intl.formatMessage({ id: I18nKey.CANCEL })}
          </Button>
          <Button onClick={handleCustAdminDelete}>
            {isDeletingCustAdmin ? (
              <CircularProgress size={30} />
            ) : (
              intl.formatMessage({ id: I18nKey.CONFIRM })
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </List>
  );
};
