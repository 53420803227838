import React, { useContext, useEffect, useMemo, useState } from "react";

import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { createApiClient } from "api/apiClient";
import {
  FormCustomerAdminDataPayload,
  GetSuperAdminOrgCustomerAdminData,
  GetSuperAdminOrgData,
  SuperAdminOrgListResponse,
} from "api/SharedTypes";
import { FormFieldObject } from "components/UserForm";
import { UserContext, UserContextState } from "contexts/userContext";
import I18nKey from "lib/I18nKeys";
import { isArray, isEmpty, isObject } from "lodash";
import { useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import ExportConstants, { FormFieldType } from "services/Utils";
import { validateEmail } from "services/utilsFunctions";

import { SuperAdminActionDialog } from "./SuperAdminActionDialog";
import "./SuperAdminHomepage.css";
import { SuperAdminUpdateDialog } from "./SuperAdminUpdateDialog";
import { useCustAdminFormData, useOrgFormData } from "./useFormData";

const SuperAdminHomepage: React.FC = () => {
  const intl = useIntl();
  const accessToken = localStorage.getItem("super_admin_access_token");
  const apiClient = createApiClient(accessToken!);
  const [physicalConsentChecked, setPhysicalConsentChecked] = useState(false);
  const [showDeviceAssigment, setShowDeviceAssigment] = useState<boolean>(true);
  const [fullOrgData, setFullOrgData] = useState<GetSuperAdminOrgData>();
  const [altheaBillingList, setAltheaBillingList] = useState<any>();
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth()
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const isAuthorizedUser =
    localStorage.getItem("username") === "altheabilling" ? true : false;
  const {
    isFetching: isLoading,
    error: isError,
    refetch: getOrgList,
  } = useQuery("get-org-list", async () => apiClient.getSuperAdminOrgList(), {
    onSuccess(data) {
      setOrgList(data);
    },
  });

  const {
    isFetching: isLoadingAltheaBilling,
    error: isErrorAltheaBilling,
    refetch: getAltheaBillingList,
  } = useQuery(
    "get-althea-billing-list",
    async () => apiClient.getAltheaBillingData(selectedMonth, selectedYear),
    {
      onSuccess(data) {
        setAltheaBillingList(data);
        console.log("setAltheaBillingList", data);
      },
    }
  );

  const { mutate: addOrg, isLoading: isAddingNewOrg } = useMutation(
    "add-new-org",
    async (orgData: GetSuperAdminOrgData) => {
      const payload = { ...orgData, physical_consent: physicalConsentChecked };
      return apiClient.createNewOrg(payload);
    },
    {
      onSuccess(data) {
        setSnackbarMessage(
          intl.formatMessage({ id: I18nKey.ORG_ADDITION_SUCESSFUL })
        );
        handleAddOrgDialogClose();
        handleAddCustAdminDialogOpen();
        setSelectedOrgId(data.id);
        getOrgList();
        handleSnackbarOpen();
        setPhysicalConsentChecked(false);
      },
      onError() {
        setSnackbarMessage(
          intl.formatMessage({ id: I18nKey.ORG_ADDITION_FAILED })
        );
        handleSnackbarOpen();
      },
    }
  );

  const { mutate: updateSelectedOrgData, isLoading: isUpdatingOrgData } =
    useMutation(
      "update-org-data",
      async (orgData: GetSuperAdminOrgData) => {
        const payload = {
          ...orgData,
          physical_consent: physicalConsentChecked,
        };
        return apiClient.updateOrgData(payload);
      },
      {
        onSuccess() {
          setSnackbarMessage(
            intl.formatMessage({ id: I18nKey.ORG_UPDATE_SUCESSFUL })
          );
          handleUpdateOrgDialogClose();
          handleSnackbarOpen();
          setPhysicalConsentChecked(false);
        },
        onError() {
          setSnackbarMessage(
            intl.formatMessage({ id: I18nKey.ORG_UPDATE_FAILED })
          );
          handleSnackbarOpen();
        },
      }
    );

  const { isLoading: isOrgDataLoading, mutate: getSelectedOrgData } =
    useMutation(
      "get-org-data",
      async (id: number) => apiClient.getSuperAdminOrgData(id),
      {
        onSuccess(data, id) {
          if (isObject(data)) {
            setSelectedOrgId(id);
            setUpdateFormData(data);
            setFullOrgData(data);
            if (data.physical_consent === 1) {
              setPhysicalConsentChecked(true);
            } else {
              setPhysicalConsentChecked(false);
            }
          }
        },
        onError: () => handleUpdateOrgDialogClose(),
      }
    );

  const {
    isLoading: isCustomerAdminDataLoading,
    isError: isCustAdminDataError,
    mutate: getOrgCustAdminList,
  } = useMutation(
    "get-customer-admin-list",
    async (id: number) => apiClient.getSuperAdminCustAdminList(id),
    {
      onSuccess: (data) => {
        if (!isEmpty(data) && isArray(data)) {
          setOrgCustAdmins(data);
          return;
        }
        setOrgCustAdmins([]);
      },
    }
  );

  const { isLoading: isGettingCustAdmin, mutate: getSelectedCustAdminData } =
    useMutation(
      "get-cust-admin-data",
      async ({ orgId, adminId }: { orgId: number; adminId: number }) =>
        apiClient.getCustAdminData(orgId, adminId),
      {
        onSuccess: (data) => {
          setCustomerAdminFormData(data);
          setSelectedCustAdminId(data.id);
        },
        onError: () => {
          handleUpdateCustAdminDialogClose();
        },
      }
    );

  const { isLoading: isAddingCustAdmin, mutate: addCustomerAdminForOrg } =
    useMutation(
      "add-cust-admin",
      async (custAdminData: FormCustomerAdminDataPayload) =>
        apiClient.addCustomerAdmin(custAdminData),
      {
        onSuccess: () => {
          setSnackbarMessage(
            intl.formatMessage({ id: I18nKey.CUST_ADMIN_ADDITION_SUCCESSFUL })
          );
          handleSnackbarOpen();
          getOrgCustAdminList(selectedOrgId);
          handleAddCustAdminDialogClose();
        },
        onError: () => {
          setSnackbarMessage(
            intl.formatMessage({ id: I18nKey.CUST_ADMIN_ADDITION_FAILED })
          );
          handleSnackbarOpen();
        },
      }
    );

  const { isLoading: isUpdatingCustAdmin, mutate: updateSelectedCustAdmin } =
    useMutation(
      "update-cust-admin",
      async (custAdminData: FormCustomerAdminDataPayload) =>
        apiClient.updateCustomerAdmin(custAdminData),
      {
        onSuccess: () => {
          setSnackbarMessage(
            intl.formatMessage({ id: I18nKey.CUST_ADMIN_UPDATE_SUCCESSFUL })
          );
          handleSnackbarOpen();
          getOrgCustAdminList(selectedOrgId);
          handleUpdateCustAdminDialogClose();
        },
        onError: () => {
          setSnackbarMessage(
            intl.formatMessage({ id: I18nKey.CUST_ADMIN_UPDATE_FAILED })
          );
          handleSnackbarOpen();
        },
      }
    );

  const { isLoading: isDeletingCustAdmin, mutate: deleteSelectedCustAdmin } =
    useMutation(
      "delete-cust-admin",
      async ({ orgId, adminId }: { orgId: number; adminId: number }) =>
        apiClient.deleteCustomerAdmin(orgId, adminId),
      {
        onSuccess: () => {
          setSnackbarMessage(
            intl.formatMessage({ id: I18nKey.CUST_ADMIN_DELETION_SUCCESSFUL })
          );
          handleSnackbarOpen();
          getOrgCustAdminList(selectedOrgId);
        },
        onError: () => {
          setSnackbarMessage(
            intl.formatMessage({ id: I18nKey.CUST_ADMIN_DELETION_FAILED })
          );
          handleSnackbarOpen();
        },
      }
    );

  const { clearOrgForm, orgFormData, setUpdateFormData, orgFormActions } =
    useOrgFormData();
  const {
    clearCustAdminForm,
    custAdminFormActions,
    custAdminFormData,
    getCustAdminFormPayload,
    setCustomerAdminFormData,
  } = useCustAdminFormData();

  const [orgList, setOrgList] = useState<SuperAdminOrgListResponse[]>([]);
  const [isUpdateOrgDialogOpen, setIsUpdateOrgDialogOpen] =
    useState<boolean>(false);
  const [selectedOrgId, setSelectedOrgId] = useState<number>(0);
  const [orgCustAdmins, setOrgCustAdmins] = useState<
    GetSuperAdminOrgCustomerAdminData[]
  >([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [isAddOrgDialogOpen, setIsAddOrgDialogOpen] = useState<boolean>(false);
  const [isAddCustAdminDialogOpen, setIsAddCustAdminDialogOpen] =
    useState<boolean>(false);
  const [isUpdateCustAdminDialogOpen, setIsUpdateCustAdminDialogOpen] =
    useState<boolean>(false);
  const [selectedCustAdminId, setSelectedCustAdminId] = useState<number>(0);
  const [isOnAtheaBilling, setIsOnAltheaBilling] = useState(false);

  const handleUpdateOrgDialogClose = () => {
    setIsUpdateOrgDialogOpen(false);
    clearOrgForm();
  };

  const handleAltheabilling = () => {
    setIsOnAltheaBilling(!isOnAtheaBilling);
  };

  const handleUpdateOrgDialogOpen = () => setIsUpdateOrgDialogOpen(true);

  const handleSnackbarOpen = () => setIsSnackbarOpen(true);

  const handleSnackbarClose = () => setIsSnackbarOpen(false);

  const handleAddOrgDialogOpen = () => {
    setIsAddOrgDialogOpen(true);
    setShowDeviceAssigment(false);
  };

  const handleAddOrgDialogClose = () => {
    setIsAddOrgDialogOpen(false);
    setShowDeviceAssigment(true);
    clearOrgForm();
  };

  useEffect(() => {
    console.log("showDeviceAssigment", showDeviceAssigment);
  }, [showDeviceAssigment]);

  const handleAddCustAdminDialogOpen = () => setIsAddCustAdminDialogOpen(true);

  const handleAddCustAdminDialogClose = () => {
    setIsAddCustAdminDialogOpen(false);
    clearCustAdminForm();
  };

  const handleUpdateCustAdminDialogOpen = () =>
    setIsUpdateCustAdminDialogOpen(true);

  const handleUpdateCustAdminDialogClose = () => {
    setIsUpdateCustAdminDialogOpen(false);
    clearCustAdminForm();
  };

  const handleOrgAdd = () => addOrg(orgFormData);

  const getOrgData = async (id: number) => {
    handleUpdateOrgDialogOpen();
    getSelectedOrgData(id);
    getOrgCustAdminList(id);
  };

  const handleOrgUpdate = () => {
    const updatedOrgData: GetSuperAdminOrgData = {
      id: selectedOrgId,
      ...orgFormData,
    };
    updateSelectedOrgData(updatedOrgData);
  };

  const getCustAdminData = (orgId: number, adminId: number) => {
    handleUpdateCustAdminDialogOpen();
    getSelectedCustAdminData({ orgId, adminId });
  };

  const deleteCustAdmin = (orgId: number, adminId: number) =>
    deleteSelectedCustAdmin({ orgId, adminId });

  const getUserName = async () => {
    if (
      custAdminFormData.firstName &&
      custAdminFormData.lastName &&
      !custAdminFormData.username
    ) {
      const username = await apiClient.getUserName(
        custAdminFormData.firstName,
        custAdminFormData.lastName
      );
      custAdminFormActions.setUsername(username);
    }
  };

  const handleAddCustAdmin = () => {
    const custAdminData: FormCustomerAdminDataPayload =
      getCustAdminFormPayload(selectedOrgId);
    addCustomerAdminForOrg(custAdminData);
  };

  const handleUpdateCustAdmin = () => {
    const custAdminData: FormCustomerAdminDataPayload =
      getCustAdminFormPayload(selectedOrgId);
    updateSelectedCustAdmin({
      ...custAdminData,
      id: selectedCustAdminId.toString(),
    });
  };

  const orgFields: FormFieldObject = {
    name: {
      type: FormFieldType.text,
      isRequired: true,
      value: orgFormData.name,
      onChange: (value) => orgFormActions.setName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_NAME,
      }),
    },
    email: {
      type: FormFieldType.text,
      isRequired: true,
      value: orgFormData.email.toLowerCase(),
      onChange: (value) => orgFormActions.setEmail(value.toLowerCase()),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_EMAIL,
      }),
      isError: orgFormData.email ? !validateEmail(orgFormData.email) : false,
      errorText:
        !validateEmail(orgFormData.email) && orgFormData.email
          ? "Please enter a valid email"
          : "",
    },
    city: {
      type: FormFieldType.text,
      isRequired: true,
      value: orgFormData.city,
      onChange: (value) => orgFormActions.setCity(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ADDRESS_CITY,
      }),
    },
    address: {
      type: FormFieldType.text,
      isRequired: true,
      value: orgFormData.address,
      onChange: (value) => orgFormActions.setAddress(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ADDRESS,
      }),
    },
    phone_1_country_code: {
      type: FormFieldType.select,
      isRequired: true,
      value: orgFormData.phone_1_country_code,
      onChange: (value) => orgFormActions.setPhone1CountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_PHONE_1_COUNTRY_CODE,
      }),
    },
    phone_1: {
      type: FormFieldType.mask,
      isRequired: true,
      mask: "###-###-####",
      value: orgFormData.phone_1,
      onChange: (value) => orgFormActions.setPhone1(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_PHONE_NUMBER_1,
      }),
    },
    phone_2_country_code: {
      type: FormFieldType.select,
      isRequired: false,
      value: orgFormData.phone_2_country_code,
      onChange: (value) => orgFormActions.setPhone2CountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_PHONE_2_COUNTRY_CODE,
      }),
    },
    phone_2: {
      type: FormFieldType.mask,
      isRequired: false,
      value: orgFormData.phone_2,
      mask: "###-###-####",
      onChange: (value) => orgFormActions.setPhone2(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_PHONE_NUMBER_2,
      }),
    },
    state: {
      type: FormFieldType.select,
      isRequired: true,
      selectOptions: ExportConstants.stateOptions,
      value: orgFormData.state,
      onChange: (value) => orgFormActions.setState(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_STATE,
      }),
    },
    zipCode: {
      type: FormFieldType.mask,
      isRequired: true,
      value: orgFormData.zipcode,
      mask: "######",
      onChange: (value) => orgFormActions.setZipCode(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ZIP_CODE,
      }),
    },
    DeviceAssignment: {
      type: FormFieldType.select,
      isRequired: true,
      selectOptions: ExportConstants.deviceAssignmentOptions,
      value: orgFormData.device_assignment,
      onChange: (value) => orgFormActions.setDeviceAssignment(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_DEVICE_ASSIGNMENT,
      }),
      isDisabled: showDeviceAssigment,
    },
    DeviceProvider: {
      type: FormFieldType.select,
      isRequired: false,
      selectOptions: ExportConstants.deviceProviderOptions,
      value: orgFormData.device_provider_name,
      onChange: (value) => orgFormActions.setDeviceProvider(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_DEVICE_PROVIDER,
      }),
    },
    RemiderDaysFrequency: {
      type: FormFieldType.number,
      isRequired: false,
      value:
        orgFormData?.reminder_days_frequency != null
          ? String(orgFormData?.reminder_days_frequency)
          : "7",
      onChange: (value) => {
        if (Number(value) < 0) {
          value = "0";
        }
        orgFormActions.setReminderDaysFrequency(Number(value));
      },

      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_REMINDER_DAYS_FREQUENCY,
      }),
      tooltip: (
        <Tooltip
          title="Consent follow-up reminder email to patients will be sent for number of days setup here. If you do not want to send any follow-up reminders set this value as 0"
          placement="top"
        >
          <InfoSharpIcon color="info" />
        </Tooltip>
      ),
    },
    ReminderWindow: {
      type: FormFieldType.select,
      isRequired: true,
      selectOptions: ExportConstants.reminderEmailOption,
      value: orgFormData.reminder_window,
      onChange: (value) => orgFormActions.setReminderWindow(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_REMINDER_WINDOW,
      }),
    },
    ProviderData: {
      type: FormFieldType.TextArea,
      isRequired: false,
      value: orgFormData.provider_options,
      onChange: (value) => orgFormActions.setProviderData(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_PROVIDER_DATA,
      }),
      multiLine: true,
    },
  };

  const custAdminFields: FormFieldObject = {
    first_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: custAdminFormData.firstName,
      onChange: (value) => custAdminFormActions.setFirstName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_FIRSTNAME,
      }),
    },
    last_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: custAdminFormData.lastName,
      onChange: (value) => custAdminFormActions.setLastName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LASTNAME,
      }),
    },
    username: {
      type: FormFieldType.text,
      isRequired: true,
      isDisabled: true,
      value: custAdminFormData.username,
      onChange: (value) => custAdminFormActions.setUsername(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_USERNAME,
      }),
    },
    email: {
      type: FormFieldType.email,
      isRequired: true,
      isDisabled: true,
      value: custAdminFormData.superAdminEmail.toLowerCase(),
      onChange: (value) =>
        custAdminFormActions.setSuperAdminEmail(value.toLowerCase()),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_EMAIL,
      }),
      isError: custAdminFormData.superAdminEmail
        ? !validateEmail(custAdminFormData.superAdminEmail)
        : false,
      errorText:
        !validateEmail(custAdminFormData.superAdminEmail) &&
        custAdminFormData.superAdminEmail
          ? "Please enter a valid email"
          : "",
    },
    gender: {
      type: FormFieldType.select,
      isRequired: true,
      value: custAdminFormData.gender,
      onChange: (value) => custAdminFormActions.setGender(value),
      selectOptions: ExportConstants.genderOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_SEX,
      }),
    },
    dob: {
      type: FormFieldType.date,
      isRequired: false,
      dateValue: custAdminFormData.dob,
      onDateChange: (value) => custAdminFormActions.setDOB(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_DATE_OF_BIRTH,
      }),
    },
    office_tel_country_code: {
      type: FormFieldType.select,
      isRequired: true,
      value: custAdminFormData.workTelCountryCode,
      onChange: (value) => custAdminFormActions.setWorkTelCountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_WORK_PHONE_COUNTRY_CODE,
      }),
    },
    office_tel: {
      type: FormFieldType.mask,
      isRequired: true,
      value: custAdminFormData.workTel,
      onChange: (value) => custAdminFormActions.setWorkTel(value),
      mask: "###-###-####",
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_WORK_PHONE,
      }),
    },
    cell_country_code: {
      type: FormFieldType.select,
      isRequired: false,
      value: custAdminFormData.mobileCountryCode,
      onChange: (value) => custAdminFormActions.setMobileCountryCode(value),
      selectOptions: ExportConstants.countryCodeOptions,
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_CELL_COUNTRY_CODE,
      }),
    },
    cell: {
      type: FormFieldType.mask,
      isRequired: false,
      value: custAdminFormData.mobile,
      onChange: (value) => custAdminFormActions.setMobile(value),
      mask: "###-###-####",
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_CELL,
      }),
    },
    office_addr_1: {
      type: FormFieldType.text,
      isRequired: false,
      value: custAdminFormData.workAddr1,
      onChange: (value) => custAdminFormActions.setWorkAddr1(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_WORK_ADDRESS_1,
      }),
    },
    office_addr_2: {
      type: FormFieldType.text,
      isRequired: false,
      value: custAdminFormData.workAddr2,
      onChange: (value) => custAdminFormActions.setWorkAddr2(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_WORK_ADDRESS_2,
      }),
    },
    address_city: {
      type: FormFieldType.text,
      isRequired: false,
      value: custAdminFormData.custAdminCity,
      onChange: (value) => custAdminFormActions.setCustAdminCity(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_WORK_ADDRESS_CITY,
      }),
    },
    state: {
      type: FormFieldType.select,
      isRequired: true,
      selectOptions: ExportConstants.stateOptions,
      value: custAdminFormData.custAdminState,
      onChange: (value) => custAdminFormActions.setCustAdminState(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_STATE,
      }),
    },
    address_zip: {
      type: FormFieldType.mask,
      isRequired: false,
      value: custAdminFormData.custAdminZipCode,
      mask: "######",
      onChange: (value) => custAdminFormActions.setCustAdminZipCode(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ZIP_CODE,
      }),
    },
    drive_license_number: {
      type: FormFieldType.text,
      isRequired: false,
      value: custAdminFormData.licenseNumber,
      onChange: (value) => custAdminFormActions.setLicenseNumber(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LICENSE_NUMBER,
      }),
    },
    is_read_only: {
      type: FormFieldType.select,
      isRequired: true,
      selectOptions: ExportConstants.adminReadOnlyOptions,
      value: custAdminFormData.readOnlyAccess.toString(),
      onChange: (value) =>
        custAdminFormActions.setReadOnlyAccess(Number(value)),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_ADMIN_READ_ONLY,
      }),
    },
    allow_add_new_patient: {
      type: FormFieldType.select,
      label: "Assign Access Rights",
      value: custAdminFormData?.custAdminAddPatient?.toString(),
      isRequired: custAdminFormData?.readOnlyAccess !== 1 ? false : true,
      selectOptions: ExportConstants?.allowAddNewPatient,
      onChange: (value) => {
        custAdminFormActions?.setCustAdminAddPatient(Number(value));
      },
      isDisabled: custAdminFormData?.readOnlyAccess === 1 ? false : true,
    },
  };

  const addCustAdminFields: FormFieldObject = {
    ...custAdminFields,
    first_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: custAdminFormData.firstName,
      onChange: (value) => custAdminFormActions.setFirstName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_FIRSTNAME,
      }),
      onBlur: getUserName,
    },
    last_name: {
      type: FormFieldType.text,
      isRequired: true,
      value: custAdminFormData.lastName,
      onChange: (value) => custAdminFormActions.setLastName(value),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_LASTNAME,
      }),
      onBlur: getUserName,
    },
    email: {
      type: FormFieldType.email,
      isRequired: true,
      value: custAdminFormData.superAdminEmail.toLowerCase(),
      onChange: (value) =>
        custAdminFormActions.setSuperAdminEmail(value.toLowerCase()),
      label: intl.formatMessage({
        id: I18nKey.USER_FORM_FIELD_EMAIL,
      }),
      isError: custAdminFormData.superAdminEmail
        ? !validateEmail(custAdminFormData.superAdminEmail)
        : false,
      errorText:
        !validateEmail(custAdminFormData.superAdminEmail) &&
        custAdminFormData.superAdminEmail
          ? "Please enter a valid email"
          : "",
    },
  };

  const updateOrgFormData = useMemo(
    () => ({ ...orgFormData, id: selectedOrgId }),
    [orgFormData, selectedOrgId]
  );

  const handleAltheaBillingFunc = () => {
    getAltheaBillingList();
  };

  const getPastFiveYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 5; i++) {
      years.push(currentYear - i);
    }
    return years;
  };

  const Months = [
    { id: 1, month: "January" },
    { id: 2, month: "February" },
    { id: 3, month: "March" },
    { id: 4, month: "April" },
    { id: 5, month: "May" },
    { id: 6, month: "June" },
    { id: 7, month: "July" },
    { id: 8, month: "August" },
    { id: 9, month: "September" },
    { id: 10, month: "October" },
    { id: 11, month: "November" },
    { id: 12, month: "December" },
  ];

  useEffect(() => {
    handleAltheaBillingFunc();
  }, []);

  return isLoading ? (
    <div className="centerLoading verticalCenter">
      <CircularProgress />
    </div>
  ) : isError ? (
    <div className="errorText">
      {intl.formatMessage({ id: I18nKey.ERROR_MESSAGE })}
    </div>
  ) : (
    <div className="homepageContainer">
      {isOnAtheaBilling ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: 50,
          }}
        >
          <div className="marginRight">
            <Button
              variant="contained"
              color="info"
              onClick={handleAltheabilling}
            >
              BACK
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              flexDirection: "row",
              gap: 20,
            }}
          >
            <FormControl
              sx={{
                width: 200,
                "& .MuiInputBase-root": {
                  height: "50px",
                },
              }}
            >
              <InputLabel id="demo-simple-select-label">Month</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Month"
                sx={{
                  width: 200,
                }}
                value={selectedMonth}
                onChange={(e: any) => setSelectedMonth(e.target.value)}
              >
                {Months.map((month: any) => (
                  <MenuItem key={month.id} value={month.id}>
                    {month.month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                width: 200,
                "& .MuiInputBase-root": {
                  height: "50px",
                },
              }}
            >
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Year"
                onChange={(e: any) => setSelectedYear(e.target.value)}
                value={selectedYear}
              >
                {getPastFiveYears().map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              color="info"
              onClick={handleAltheaBillingFunc}
              sx={{
                padding: "5px 10px",
                fontSize: "16px",
              }}
            >
              Search
            </Button>
          </div>
        </div>
      ) : (
        <div className="flexEndContainer">
          <div className="marginRight">
            <Button
              variant="contained"
              color="info"
              onClick={handleAltheabilling}
            >
              ALTHEA BILLING
            </Button>
          </div>
          <div className="marginRight">
            <Button
              variant="contained"
              color="info"
              onClick={handleAddOrgDialogOpen}
              disabled={isAuthorizedUser}
            >
              {intl.formatMessage({
                id: I18nKey.EXCEPTION_DIALOG_BUTTON_CREATE_NEW,
              })}
            </Button>
          </div>
          <div className="marginRight">
            <Button
              variant="contained"
              color="warning"
              disabled={isAuthorizedUser}
            >
              {intl.formatMessage({
                id: I18nKey.RESTORE_USERS,
              })}
            </Button>
          </div>
        </div>
      )}

      {isOnAtheaBilling ? (
        <>
          {isLoadingAltheaBilling ? (
            <div className="centerLoading verticalCenter">
              <CircularProgress />
            </div>
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ paddingLeft: 10 }}>ORGANIZATION</TableCell>
                    <TableCell align="center">BILLS GENRATED</TableCell>
                  </TableRow>
                </TableHead>
                {altheaBillingList?.length === 0 ? (
                  <div style={{ padding: 20 }}>No Bills Found.</div>
                ) : (
                  <TableBody>
                    {altheaBillingList &&
                      altheaBillingList.map((row: any) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            sx={{ paddingLeft: 10 }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.count}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}
        </>
      ) : (
        <>
          <List>
            {orgList &&
              orgList
                ?.sort((a, b) => a?.name?.localeCompare(b.name))
                ?.map((org) => (
                  <ListItem key={org.id} disablePadding>
                    <ListItemButton
                      disabled={isAuthorizedUser}
                      onClick={() => {
                        getOrgData(org.id);
                      }}
                    >
                      <ListItemText
                        primary={org.name}
                        sx={{ color: "#3480aa" }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
          </List>
        </>
      )}

      <SuperAdminUpdateDialog
        handleClose={handleUpdateOrgDialogClose}
        isOpen={isUpdateOrgDialogOpen}
        orgData={updateOrgFormData}
        custAdmins={orgCustAdmins}
        formFields={orgFields}
        handleUpdate={handleOrgUpdate}
        getCustAdminData={getCustAdminData}
        handleAddCustAdminDialogOpen={handleAddCustAdminDialogOpen}
        deleteCustAdmin={deleteCustAdmin}
        isOrgDataLoading={isOrgDataLoading}
        isCustomerAdminDataLoading={isCustomerAdminDataLoading}
        isCustAdminDataError={isCustAdminDataError}
        isUpdatingOrgData={isUpdatingOrgData}
        isDeletingCustAdmin={isDeletingCustAdmin}
        physicalConsent={physicalConsentChecked}
        physicalConsentHandler={setPhysicalConsentChecked}
        fullOrgData={fullOrgData}
      />
      <SuperAdminActionDialog
        dialogTitle={intl.formatMessage({ id: I18nKey.ADD_ORGANIZATION })}
        handleDialogAction={handleOrgAdd}
        isOpen={isAddOrgDialogOpen}
        formFields={orgFields}
        handleClose={handleAddOrgDialogClose}
        actionButtonText={intl.formatMessage({
          id: I18nKey.CREATE,
        })}
        isLoading={isAddingNewOrg}
        physicalConsent={physicalConsentChecked}
        physicalConsentHandler={setPhysicalConsentChecked}
      />
      <SuperAdminActionDialog
        dialogTitle={intl.formatMessage({ id: I18nKey.ADD_CUST_ADMIN })}
        handleDialogAction={handleAddCustAdmin}
        isOpen={isAddCustAdminDialogOpen}
        formFields={addCustAdminFields}
        handleClose={handleAddCustAdminDialogClose}
        actionButtonText={intl.formatMessage({
          id: I18nKey.CREATE,
        })}
        isLoading={isAddingCustAdmin}
        physicalConsent={physicalConsentChecked}
        physicalConsentHandler={setPhysicalConsentChecked}
      />
      <SuperAdminActionDialog
        dialogTitle={intl.formatMessage({ id: I18nKey.UPDATE_CUST_ADMIN })}
        handleDialogAction={handleUpdateCustAdmin}
        isOpen={isUpdateCustAdminDialogOpen}
        formFields={custAdminFields}
        handleClose={handleUpdateCustAdminDialogClose}
        actionButtonText={intl.formatMessage({
          id: I18nKey.UPDATE,
        })}
        isLoading={isUpdatingCustAdmin}
        isGettingCustAdmin={isGettingCustAdmin}
        physicalConsent={physicalConsentChecked}
        physicalConsentHandler={setPhysicalConsentChecked}
        // showDeviceAssigment={setShowDeviceAssigment}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
};
export default SuperAdminHomepage;
