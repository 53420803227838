import { useState } from "react";

import {
  FormCustomerAdminDataPayload,
  GetCustomerAdminDataResponse,
  GetSuperAdminOrgData,
} from "api/SharedTypes";
import { DateTime } from "luxon";
import ExportConstants from "services/Utils";

export const useOrgFormData = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [phone1, setPhone1] = useState<string>("");
  const [phone1CountryCode, setPhone1CountryCode] = useState<string>(
    ExportConstants.countryCodeOptions[0].value.toString()
  );
  const [phone2, setPhone2] = useState<string>("");
  const [phone2CountryCode, setPhone2CountryCode] = useState<string>(
    ExportConstants.countryCodeOptions[0].value.toString()
  );
  const [state, setState] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [deviceAssignment, setDeviceAssignment] = useState<string>("");
  const [deviceProvider, setDeviceProvider] = useState<string>("");
  const [providerData, setProviderData] = useState<string>("");
  const [reminderWindow, setReminderWindow] = useState<string>("");
  const [reminderDaysFrequency, setReminderDaysFrequency] = useState<number>(7);

  const setUpdateFormData = (data: GetSuperAdminOrgData) => {
    setName(data.name);
    setEmail(data.email);
    setCity(data.city);
    setAddress(data.address);
    setPhone1(data.phone_1);
    setPhone2(data.phone_2);
    setState(data.state);
    setZipCode(data.zipcode);
    setPhone1CountryCode(
      data.phone_1_country_code ||
        ExportConstants.countryCodeOptions[0].value.toString()
    );
    setPhone2CountryCode(
      data.phone_2_country_code ||
        ExportConstants.countryCodeOptions[0].value.toString()
    );
    setDeviceAssignment(data.device_assignment);
    setDeviceProvider(data.device_provider_name);
    setProviderData(data.provider_options);
    setReminderWindow(data.reminder_window);
    setReminderDaysFrequency(data.reminder_days_frequency);
  };

  const orgFormData: GetSuperAdminOrgData = {
    name: name,
    email: email,
    city: city,
    address: address,
    phone_1: phone1.replaceAll("-", ""),
    phone_2: phone2.replaceAll("-", ""),
    phone_1_country_code: phone1CountryCode,
    phone_2_country_code: phone2CountryCode,
    state: state,
    zipcode: zipCode,
    device_assignment: deviceAssignment,
    device_provider_name: deviceProvider,
    provider_options: providerData,
    reminder_window: reminderWindow,
    reminder_days_frequency:
      reminderDaysFrequency == null ? 7 : reminderDaysFrequency,
  };

  const clearOrgForm = () => {
    setName("");
    setEmail("");
    setCity("");
    setAddress("");
    setPhone1("");
    setPhone2("");
    setPhone1CountryCode(
      ExportConstants.countryCodeOptions[0].value.toString()
    );
    setPhone2CountryCode(
      ExportConstants.countryCodeOptions[0].value.toString()
    );
    setState("");
    setZipCode("");
    setDeviceAssignment("");
    setDeviceProvider("");
    setProviderData("");
    setReminderWindow("");
    setReminderDaysFrequency(7);
  };

  const orgFormActions = {
    setName,
    setEmail,
    setCity,
    setAddress,
    setPhone1,
    setPhone2,
    setPhone1CountryCode,
    setPhone2CountryCode,
    setState,
    setZipCode,
    setDeviceAssignment,
    setDeviceProvider,
    setProviderData,
    setReminderWindow,
    setReminderDaysFrequency,
  };

  return { orgFormData, clearOrgForm, setUpdateFormData, orgFormActions };
};

export const useCustAdminFormData = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [superAdminEmail, setSuperAdminEmail] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [dob, setDOB] = useState<DateTime | null>(null);
  const [workTel, setWorkTel] = useState<string>("");
  const [workTelCountryCode, setWorkTelCountryCode] = useState<string>(
    ExportConstants.countryCodeOptions[0].value.toString()
  );
  const [mobile, setMobile] = useState<string>("");
  const [mobileCountryCode, setMobileCountryCode] = useState<string>(
    ExportConstants.countryCodeOptions[0].value.toString()
  );
  const [workAddr1, setWorkAddr1] = useState<string>("");
  const [workAddr2, setWorkAddr2] = useState<string>("");
  const [licenseNumber, setLicenseNumber] = useState<string>("");
  const [readOnlyAccess, setReadOnlyAccess] = useState<number>(0);
  const [custAdminCity, setCustAdminCity] = useState<string>("");
  const [custAdminState, setCustAdminState] = useState<string>("");
  const [custAdminZipCode, setCustAdminZipCode] = useState<string>("");
  const [custAdminAddPatient, setCustAdminAddPatient] = useState<any>(0);

  const clearCustAdminForm = () => {
    setFirstName("");
    setLastName("");
    setUsername("");
    setSuperAdminEmail("");
    setGender("");
    setDOB(null);
    setWorkTel("");
    setMobile("");
    setWorkAddr1("");
    setWorkAddr2("");
    setCustAdminCity("");
    setCustAdminState("");
    setCustAdminZipCode("");
    setMobileCountryCode(
      ExportConstants.countryCodeOptions[0].value.toString()
    );
    setWorkTelCountryCode(
      ExportConstants.countryCodeOptions[0].value.toString()
    );
    setLicenseNumber("");
    setReadOnlyAccess(0);
    setCustAdminAddPatient(0);
  };

  const setCustomerAdminFormData = (data: GetCustomerAdminDataResponse) => {
    setFirstName(data.first_name);
    setUsername(data.username);
    setLastName(data.last_name);
    setSuperAdminEmail(data.email);
    setGender(data.gender);
    setDOB(data.dob ? DateTime.fromFormat(data.dob, "MM-dd-yyyy") : null);
    setMobile(data.cell);
    setWorkTel(data.office_tel);
    setWorkAddr1(data.office_addr_1);
    setWorkAddr2(data.office_addr_2);
    setCustAdminCity(data.address_city);
    setCustAdminState(data.state);
    setCustAdminZipCode(data.address_zip);
    setLicenseNumber(data.drive_license_number);
    setReadOnlyAccess(data.is_read_only);
    setWorkTelCountryCode(
      data.office_tel_country_code ||
        ExportConstants.countryCodeOptions[0].value.toString()
    );
    setMobileCountryCode(
      data.cell_country_code ||
        ExportConstants.countryCodeOptions[0].value.toString()
    );
    setCustAdminAddPatient(data.allow_add_new_patient);
  };

  const getCustAdminFormPayload = (
    selectedOrgId: number
  ): FormCustomerAdminDataPayload => ({
    first_name: firstName,
    last_name: lastName,
    username: username,
    dob: dob?.toFormat("MM-dd-yyyy") || "",
    office_tel: workTel.replaceAll("-", ""),
    office_tel_country_code: workTelCountryCode,
    cell: mobile.replaceAll("-", ""),
    cell_country_code: mobileCountryCode,
    office_addr_1: workAddr1,
    office_addr_2: workAddr2,
    address_city: custAdminCity,
    address_zip: custAdminZipCode,
    drive_license_number: licenseNumber,
    gender: gender,
    state: custAdminState,
    org_id: selectedOrgId.toString(),
    is_read_only: readOnlyAccess.toString(),
    email: superAdminEmail,
    role: "customer_admin",
    allow_add_new_patient: custAdminAddPatient.toString(),
  });

  const custAdminFormData = {
    firstName,
    lastName,
    username,
    superAdminEmail,
    gender,
    dob,
    workTel,
    workTelCountryCode,
    mobile,
    mobileCountryCode,
    workAddr1,
    workAddr2,
    licenseNumber,
    readOnlyAccess,
    custAdminCity,
    custAdminState,
    custAdminZipCode,
    custAdminAddPatient,
  };

  const custAdminFormActions = {
    setFirstName,
    setLastName,
    setUsername,
    setSuperAdminEmail,
    setGender,
    setDOB,
    setWorkTel,
    setWorkTelCountryCode,
    setMobile,
    setMobileCountryCode,
    setWorkAddr1,
    setWorkAddr2,
    setLicenseNumber,
    setReadOnlyAccess,
    setCustAdminCity,
    setCustAdminState,
    setCustAdminZipCode,
    setCustAdminAddPatient,
  };
  return {
    custAdminFormData,
    custAdminFormActions,
    getCustAdminFormPayload,
    setCustomerAdminFormData,
    clearCustAdminForm,
  };
};
