import React, { useContext, useEffect, useState } from "react";

import { createApiClient } from "api/apiClient";
import { UserContextState, UserContext } from "contexts/userContext";
import jwtDecode from "jwt-decode";
import { DateTime } from "luxon";
import { SuperAdminAppbar } from "pages/SuperAdminAppbar";
import SuperAdminHomepage from "pages/SuperAdminHomepage";

import SuperAdminLogin from "./SuperAdminLogin";
import "./SuperAdminRoute.css";

export interface JWTToken {
  readonly auth_time: number;
  readonly client_id: string;
  readonly "cognito:groups": string[];
  readonly event_id: string;
  readonly exp: number;
  readonly iat: number;
  readonly iss: string;
  readonly jti: string;
  readonly origin_jti: string;
  readonly scope: string;
  readonly sub: string;
  readonly token_use: string;
  readonly username: string;
}

const SuperAdminRoute: React.FC = () => {
  const userContext = useContext<UserContextState>(UserContext);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");

  const accessToken = localStorage.getItem("super_admin_access_token");

  useEffect(() => {
    if (accessToken) {
      const decoded: JWTToken = jwtDecode(accessToken);
      if (DateTime.now().toMillis() < decoded.exp * 1000) {
        createApiClient(accessToken)
          .getLoggedInUserData()
          .then((userData) => {
            userContext.setLoggedInUserData(userData);
            if (userData.role === "super_admin") {
              setIsAuthenticated(true);
              setUsername(decoded.username);
              localStorage.setItem("username", decoded.username);
            } else {
              setIsAuthenticated(false);
              localStorage.clear();
            }
          });
      } else {
        setIsAuthenticated(false);
        localStorage.clear();
      }
    }
  }, [accessToken, userContext]);

  const logOutUser = () => {
    setIsAuthenticated(false);
    localStorage.clear();
  };

  return isAuthenticated ? (
    <>
      <SuperAdminAppbar username={username} logoutUser={logOutUser}>
        <SuperAdminHomepage />
      </SuperAdminAppbar>
    </>
  ) : (
    <SuperAdminLogin setIsAuthenticated={setIsAuthenticated} />
  );
};
export default SuperAdminRoute;
