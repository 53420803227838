import React from "react";

import { TextField } from "@mui/material";
import { FormFieldData } from "components/UserForm";
import NumberFormat, { SyntheticInputEvent } from "react-number-format";

interface MaskedFieldProps {
  readonly fieldItem: FormFieldData;
}

const MaskedField: React.FC<MaskedFieldProps> = ({ fieldItem }) => {
  return (
    <NumberFormat
      customInput={TextField}
      variant="standard"
      value={fieldItem.value}
      fullWidth
      format={fieldItem.mask}
      label={fieldItem.label}
      required={fieldItem.isRequired}
      onChange={(e: SyntheticInputEvent) => {
        fieldItem.onChange?.(e.target.value);
      }}
      disabled={fieldItem.isDisabled}
    />
  );
};
export default MaskedField;
