import React, { useState } from "react";

import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import {
  GetSuperAdminOrgCustomerAdminData,
  GetSuperAdminOrgData,
} from "api/SharedTypes";
import { FormFieldObject } from "components/UserForm";
import { UserFormFields } from "components/UserFormFields";
import I18nKey from "lib/I18nKeys";
import { useIntl } from "react-intl";
import { FormFieldType } from "services/Utils";
import { getIsDOBInvalid, validateEmail } from "services/utilsFunctions";

import { CustomerAdmin } from "./CustomerAdmin";
import { LogoUploader } from "./LogoUploader";
import { PolicyUploader } from "./PolicyUploader";
import { VBC } from "./VBC";

interface SuperAdminUpdateDialogProps {
  readonly isOpen: boolean;
  readonly handleClose: () => void;
  readonly formFields: FormFieldObject;
  readonly custAdmins: GetSuperAdminOrgCustomerAdminData[];
  readonly orgData: GetSuperAdminOrgData;
  readonly handleUpdate: () => void;
  readonly getCustAdminData: (orgId: number, adminId: number) => void;
  readonly handleAddCustAdminDialogOpen: () => void;
  readonly deleteCustAdmin: (orgId: number, adminId: number) => void;
  readonly isOrgDataLoading: boolean;
  readonly isCustomerAdminDataLoading: boolean;
  readonly isCustAdminDataError: boolean;
  readonly isUpdatingOrgData: boolean;
  readonly isDeletingCustAdmin: boolean;
  readonly physicalConsent: boolean;
  readonly physicalConsentHandler: (flag: boolean) => void;
  readonly fullOrgData: GetSuperAdminOrgData | undefined;
}

interface TabPanelProps {
  readonly title: string;
  readonly value: string;
  readonly children: JSX.Element;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  title,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      className="tabPanel"
      hidden={value !== title}
      id={`simple-tabpanel-${title}`}
      aria-labelledby={`simple-tab-${title}`}
      {...other}
    >
      <React.Fragment>{children}</React.Fragment>
    </div>
  );
};

function a11yProps(title: string) {
  return {
    id: `simple-tab-${title}`,
    "aria-controls": `simple-tabpanel-${title}`,
  };
}

export enum TabName {
  orgInfo = "orgInfo",
  custAdmins = "custAdmins",
  orgLogo = "orgLogo",
  orgPrivacyPolicy = "orgPrivacyPolicy",
  vbc = "vbc",
}

export const SuperAdminUpdateDialog: React.FC<SuperAdminUpdateDialogProps> = ({
  isOpen,
  handleClose,
  formFields,
  custAdmins,
  orgData,
  handleUpdate,
  getCustAdminData,
  handleAddCustAdminDialogOpen,
  deleteCustAdmin,
  isOrgDataLoading,
  isCustAdminDataError,
  isCustomerAdminDataLoading,
  isUpdatingOrgData,
  isDeletingCustAdmin,
  physicalConsent,
  physicalConsentHandler,
  fullOrgData,
}) => {
  const intl = useIntl();

  const [tab, setTab] = useState<TabName>(TabName.orgInfo);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: TabName) =>
    setTab(newValue);

  const isSubmitDisabled = () => {
    const requiredFields = Object.keys(formFields).filter(
      (field) => formFields[field].isRequired
    );
    const isRequiredFilled = requiredFields.map((field) => {
      if (formFields[field].type === FormFieldType.date) {
        if (!formFields[field].dateValue) {
          return true;
        }
        if (
          formFields[field].dateValue &&
          formFields[field].dateValue?.invalidReason
        ) {
          return true;
        }
        return false;
      }
      if (field === "email") {
        if (formFields[field].value) {
          if (validateEmail(formFields[field].value || "")) {
            return false;
          }
        }
        return true;
      }
      return formFields[field].value ? false : true;
    });
    const isDOBRequired = formFields["dob"]?.isRequired || false;
    const isDOBInvalid = getIsDOBInvalid(
      formFields["dob"]?.dateValue,
      isDOBRequired
    );
    return (
      isRequiredFilled.reduce((acc, nextValue) => acc || nextValue) ||
      isUpdatingOrgData ||
      isDOBInvalid
    );
  };

  const handleDialogClose = () => {
    setTab(TabName.orgInfo);
    handleClose();
    physicalConsentHandler(false);
  };

  let showPhysicalConsent = false;

  if (tab === TabName.orgInfo) {
    showPhysicalConsent = true;
  }

  return (
    <>
      <Dialog open={isOpen} onClose={handleDialogClose} maxWidth="lg">
        <DialogTitle>{orgData?.name}</DialogTitle>
        {isOrgDataLoading ? (
          <div className="centerLoading">
            <CircularProgress />
          </div>
        ) : (
          <DialogContent>
            <Tabs value={tab} centered onChange={handleTabChange}>
              <Tab
                label={`ORGANIZATION INFO`}
                value={TabName.orgInfo}
                {...a11yProps(TabName.orgInfo)}
              />
              <Tab
                label="ADD/REMOVE CUSTOMER ADMINS"
                value={TabName.custAdmins}
                {...a11yProps(TabName.custAdmins)}
              />
              <Tab
                label="Logo"
                value={TabName.orgLogo}
                {...a11yProps(TabName.orgLogo)}
              />
              <Tab
                label="Privacy Policy"
                value={TabName.orgPrivacyPolicy}
                {...a11yProps(TabName.orgPrivacyPolicy)}
              />
              <Tab
                label="VBC"
                value={TabName.vbc}
                {...a11yProps(TabName.vbc)}
              />
            </Tabs>
            <TabPanel value={tab} title={TabName.orgInfo}>
              <UserFormFields formFields={formFields} />
            </TabPanel>
            {showPhysicalConsent && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  marginTop: "0.5rem",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        checked={physicalConsent}
                        onClick={() => physicalConsentHandler(!physicalConsent)}
                      />
                    }
                    label="Accept Physical Consent"
                    style={{
                      marginTop: "0.5rem",
                    }}
                  />
                </FormGroup>
                <Tooltip
                  title="If this flag is selected, then patients will be visible to Practice CareTeam even if no Digital Consent is in place"
                  placement="top"
                >
                  <InfoSharpIcon color="info" />
                </Tooltip>
              </Box>
            )}
            <TabPanel value={tab} title={TabName.custAdmins}>
              <CustomerAdmin
                custAdmins={custAdmins}
                getCustAdminData={getCustAdminData}
                handleAddCustAdminDialogOpen={handleAddCustAdminDialogOpen}
                deleteCustAdmin={deleteCustAdmin}
                isCustAdminDataError={isCustAdminDataError}
                isCustomerAdminDataLoading={isCustomerAdminDataLoading}
                isDeletingCustAdmin={isDeletingCustAdmin}
              />
            </TabPanel>
            <TabPanel value={tab} title={TabName.orgLogo}>
              <LogoUploader fullOrgData={fullOrgData} />
            </TabPanel>
            <TabPanel value={tab} title={TabName.orgPrivacyPolicy}>
              <PolicyUploader fullOrgData={fullOrgData} />
            </TabPanel>
            <TabPanel value={tab} title={TabName.vbc}>
              <VBC fullOrgData={fullOrgData}></VBC>
            </TabPanel>
          </DialogContent>
        )}
        <DialogActions>
          {tab === TabName.orgInfo && (
            <div>
              <Button
                variant="contained"
                disabled={isSubmitDisabled()}
                onClick={handleUpdate}
              >
                {isUpdatingOrgData ? (
                  <CircularProgress size={30} />
                ) : (
                  intl.formatMessage({
                    id: I18nKey.UPDATE,
                  })
                )}
              </Button>
            </div>
          )}
          <Button onClick={handleDialogClose}>
            {intl.formatMessage({ id: I18nKey.CANCEL })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
