import axios from "axios";
import jwtDecode from "jwt-decode";
import { DateTime } from "luxon";

import {
  SuperAdminOrgListResponse,
  GetSuperAdminOrgData,
  GetSuperAdminOrgCustomerAdminData,
  UpdateOrgDataResponse,
  CreateNewOrgResponse,
  GetCustomerAdminDataResponse,
  FormCustomerAdminDataPayload,
  UserData,
} from "./SharedTypes";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

interface ApiClient {
  readonly getAltheaBillingData: (month: any, year: any) => Promise<any>;
  readonly getLoggedInUserData: () => Promise<UserData>;
  readonly getUserName: (
    firstName: string,
    lastName: string
  ) => Promise<string>;
  readonly getSuperAdminOrgList: () => Promise<SuperAdminOrgListResponse[]>;
  readonly getSuperAdminOrgData: (id: number) => Promise<GetSuperAdminOrgData>;
  readonly getSuperAdminCustAdminList: (
    id: number
  ) => Promise<GetSuperAdminOrgCustomerAdminData[]>;
  readonly updateOrgData: (
    orgData: GetSuperAdminOrgData
  ) => Promise<UpdateOrgDataResponse>;
  readonly createNewOrg: (
    orgData: GetSuperAdminOrgData
  ) => Promise<CreateNewOrgResponse>;
  readonly getCustAdminData: (
    orgId: number,
    adminId: number
  ) => Promise<GetCustomerAdminDataResponse>;
  readonly addCustomerAdmin: (
    data: FormCustomerAdminDataPayload
  ) => Promise<GetCustomerAdminDataResponse>;
  readonly deleteCustomerAdmin: (
    orgId: number,
    adminId: number
  ) => Promise<UpdateOrgDataResponse>;
  readonly updateCustomerAdmin: (
    data: FormCustomerAdminDataPayload
  ) => Promise<GetCustomerAdminDataResponse>;
  readonly getVBCPrograms: (org_id: number | undefined) => Promise<any>;
  readonly postUploadOrgFiles: (
    byteStr: string | undefined,
    org_id: number | undefined,
    type: "policy" | "logo"
  ) => Promise<any>;
  readonly postVBCList: (
    org_id: number | undefined,
    vbc: number[]
  ) => Promise<any>;
}

const PATHS = Object.freeze({
  GET_USER_NAME: `users/username`,
  GET_SUPER_ADMIN_ORG_LIST: "org/orgs",
  CREATE_NEW_ORG: "org/",
  GET_SUPER_ADMIN_ORG_DATA: (id: number) => `org/${id}`,
  GET_SUPER_ADMIN_CUST_ADMIN_LIST: (id: number) => `org/${id}/admins`,
  GET_CUST_ADMIN_DATA: (orgId: number, adminId: number) =>
    `org/${orgId}/admin/${adminId}`,
  ADD_CUST_ADMIN: (id: number) => `org/${id}/admin`,
  DELETE_CUST_ADMIN: (orgId: number, adminId: number) =>
    `org/${orgId}/admin/${adminId}`,
  GET_LOGGED_IN_USER_DATA: "users/auth",
  POST_UPLOAD_ORG_FILES: "org/upload_org_files",
  GET_ORG_VBC: (id: number) => `org/get_all_vbc_list/${id}`,
  POST_ORG_VBC: "org/store_org_vbc",
  GET_ALTHEA_BILLING: `org/althea/billing/`,
});

const checkTokenExpiry = () => {
  const accessToken = localStorage.getItem("super_admin_access_token") || "";
  if (accessToken) {
    const token: any = jwtDecode(accessToken);
    if (DateTime.now().toMillis() >= token.exp * 1000) {
      localStorage.clear();
      window.location.pathname = "/";
    }
  }
};

export const createApiClient = (token: string): ApiClient => {
  const config = {
    baseURL: BASE_URL,
    headers: {
      Authorization: token,
    },
  };

  const getUserName = async (
    firstName: string,
    lastName: string
  ): Promise<string> => {
    checkTokenExpiry();
    const response = await axios.get<string>(
      PATHS.GET_USER_NAME,

      {
        ...config,
        params: { first_name: firstName, last_name: lastName },
      }
    );
    return response.data;
  };

  const getSuperAdminOrgList = async (): Promise<
    SuperAdminOrgListResponse[]
  > => {
    checkTokenExpiry();
    const response = await axios.get<SuperAdminOrgListResponse[]>(
      PATHS.GET_SUPER_ADMIN_ORG_LIST,
      config
    );
    return response.data;
  };

  const getSuperAdminOrgData = async (
    id: number
  ): Promise<GetSuperAdminOrgData> => {
    checkTokenExpiry();
    const response = await axios.get<GetSuperAdminOrgData>(
      PATHS.GET_SUPER_ADMIN_ORG_DATA(id),
      config
    );
    return response.data;
  };

  const getSuperAdminCustAdminList = async (id: number) => {
    checkTokenExpiry();
    const response = await axios.get<GetSuperAdminOrgCustomerAdminData[]>(
      PATHS.GET_SUPER_ADMIN_CUST_ADMIN_LIST(id),
      config
    );
    return response.data;
  };

  const updateOrgData = async (orgData: GetSuperAdminOrgData) => {
    checkTokenExpiry();
    if (orgData.id) {
      const response = await axios.put(
        PATHS.GET_SUPER_ADMIN_ORG_DATA(orgData.id),
        orgData,
        config
      );
      return response.data;
    }
  };

  const createNewOrg = async (orgData: GetSuperAdminOrgData) => {
    checkTokenExpiry();
    const response = await axios.post(PATHS.CREATE_NEW_ORG, orgData, config);
    return response.data;
  };

  const getCustAdminData = async (
    orgId: number,
    adminId: number
  ): Promise<GetCustomerAdminDataResponse> => {
    checkTokenExpiry();
    const response = await axios.get<GetCustomerAdminDataResponse>(
      PATHS.GET_CUST_ADMIN_DATA(orgId, adminId),
      config
    );
    return response.data;
  };

  const addCustomerAdmin = async (data: FormCustomerAdminDataPayload) => {
    checkTokenExpiry();
    const response = await axios.post(
      PATHS.ADD_CUST_ADMIN(Number(data.org_id)),
      data,
      config
    );
    return response.data;
  };

  const deleteCustomerAdmin = async (
    orgId: number,
    adminId: number
  ): Promise<UpdateOrgDataResponse> => {
    checkTokenExpiry();
    const response = await axios.delete<UpdateOrgDataResponse>(
      PATHS.DELETE_CUST_ADMIN(orgId, adminId),
      config
    );
    return response.data;
  };

  const updateCustomerAdmin = async (data: FormCustomerAdminDataPayload) => {
    checkTokenExpiry();
    const response = await axios.put(
      PATHS.GET_CUST_ADMIN_DATA(Number(data.org_id), Number(data.id)),
      data,
      config
    );
    return response.data;
  };

  const getLoggedInUserData = async () => {
    checkTokenExpiry();
    const response = await axios.get(PATHS.GET_LOGGED_IN_USER_DATA, config);
    return response.data;
  };

  const getVBCPrograms = async (org_id: number | undefined) => {
    checkTokenExpiry();
    const response = await axios.get(PATHS.GET_ORG_VBC(Number(org_id)), config);
    return response.data;
  };

  const postUploadOrgFiles = async (
    byteStr: string | undefined,
    org_id: number | undefined,
    type: "policy" | "logo"
  ) => {
    checkTokenExpiry();
    const body = {
      byteStr: byteStr,
      org_id: org_id,
      type: type,
    };
    const response = await axios.post(
      PATHS.POST_UPLOAD_ORG_FILES,
      body,
      config
    );
    return response.data;
  };

  const postVBCList = async (org_id: number | undefined, vbc: number[]) => {
    checkTokenExpiry();
    const body = {
      org_id: org_id,
      vbc: vbc,
    };
    const response = await axios.post(PATHS.POST_ORG_VBC, body, config);
    return response.data;
  };

  const getAltheaBillingData = async (month: any, year: any) => {
    checkTokenExpiry();
    const response = await axios.get(
      `${PATHS.GET_ALTHEA_BILLING}${month}/${year}`,
      config
    );
    return response.data;
  };

  return {
    getUserName,
    addCustomerAdmin,
    createNewOrg,
    getSuperAdminOrgList,
    getSuperAdminOrgData,
    getSuperAdminCustAdminList,
    getCustAdminData,
    updateCustomerAdmin,
    updateOrgData,
    deleteCustomerAdmin,
    getLoggedInUserData,
    getVBCPrograms,
    postUploadOrgFiles,
    postVBCList,
    getAltheaBillingData,
  };
};
