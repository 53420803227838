import React from "react";

import { Grid } from "@mui/material";
import { FormFieldObject } from "components/UserForm";
import { FormFieldType } from "services/Utils";

import DateField from "./DateField";
import MaskedField from "./MaskedField";
import PlainTextField from "./PlainTextField";
import SelectField from "./SelectField";

interface UserFormFieldsProps {
  readonly formFields: FormFieldObject;
}

export const UserFormFields: React.FC<UserFormFieldsProps> = ({
  formFields,
}) => {
  return (
    <Grid container spacing={2}>
      {Object.keys(formFields).map((field) => {
        const fieldItem = formFields[field];
        if (fieldItem.type === FormFieldType.date) {
          return (
            <Grid key={fieldItem.label} item xs={6}>
              <DateField fieldItem={fieldItem} />
            </Grid>
          );
        }
        if (fieldItem.type === FormFieldType.mask) {
          return (
            <Grid key={fieldItem.label} item xs={6}>
              <MaskedField fieldItem={fieldItem} />
            </Grid>
          );
        }
        if (fieldItem.type === FormFieldType.select) {
          return (
            <Grid key={fieldItem.label} item xs={6}>
              <SelectField fieldItem={fieldItem} />
            </Grid>
          );
        }
        if (fieldItem.type === FormFieldType.TextArea) {
          return (
            <Grid key={fieldItem.label} item xs={12}>
              <PlainTextField fieldItem={fieldItem} />
            </Grid>
          );
        }
        return (
          <Grid key={fieldItem.label} item xs={6}>
            <PlainTextField fieldItem={fieldItem} />
          </Grid>
        );
      })}
    </Grid>
  );
};
