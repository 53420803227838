import React from "react";

import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormFieldData } from "components/UserForm";
import { DateTime } from "luxon";

interface DateFieldProps {
  readonly fieldItem: FormFieldData;
}

const DateField: React.FC<DateFieldProps> = ({ fieldItem }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker
        disableFuture
        label={fieldItem.label}
        openTo="year"
        inputFormat="MM/dd/yyyy"
        views={["year", "month", "day"]}
        value={fieldItem.dateValue}
        onChange={(newValue: DateTime | null) => {
          fieldItem.onDateChange?.(newValue);
        }}
        disabled={fieldItem.isDisabled}
        renderInput={(params: any) => {
          const { inputProps, ...other } = params;
          return (
            <TextField
              fullWidth
              required={fieldItem.isRequired}
              variant="standard"
              {...other}
              placeholder={inputProps?.placeholder || "mm/dd/yyyy"}
              value={inputProps?.value}
              onChange={inputProps?.onChange}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};
export default DateField;
