import { DateTime } from "luxon";

const EMAIL_VALIDATION_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email: string) => {
  const emailValidationRegex = new RegExp(EMAIL_VALIDATION_REGEX);
  return emailValidationRegex.test(email);
};

const getIsInvalidReason = (dateValue: DateTime) => {
  if (dateValue && dateValue?.invalidReason) {
    return true;
  }
  return false;
};

export const getIsDOBInvalid = (
  dateValue: DateTime | null | undefined,
  isRequired: boolean
) => {
  if (isRequired) {
    if (!dateValue) {
      return true;
    }
    return getIsInvalidReason(dateValue);
  }
  if (!dateValue) {
    return false;
  }
  return getIsInvalidReason(dateValue);
};
