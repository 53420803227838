import React from "react";

import { TextField } from "@mui/material";
import { FormFieldData } from "components/UserForm";

interface PlainTextFieldProps {
  readonly fieldItem: FormFieldData;
}

const PlainTextField: React.FC<PlainTextFieldProps> = ({ fieldItem }) => {
  return (
    <>
      <TextField
        label={
          fieldItem.tooltip ? (
            <>
              <>{fieldItem.label}</> <>{fieldItem.tooltip}</>
            </>
          ) : (
            fieldItem.label
          )
        }
        type={fieldItem.type}
        fullWidth
        defaultValue={fieldItem.defaultValue}
        disabled={fieldItem.isDisabled}
        variant="standard"
        error={fieldItem.isError}
        placeholder={fieldItem.label}
        helperText={fieldItem.errorText}
        required={fieldItem.isRequired}
        value={fieldItem.value}
        onChange={(e) => {
          fieldItem.onChange?.(e.target.value);
        }}
        inputProps={{ maxLength: fieldItem.maxLength }}
        onBlur={fieldItem.onBlur}
        multiline={fieldItem.multiLine}
      />
    </>
  );
};
export default PlainTextField;
