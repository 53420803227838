import React, { useState, useEffect } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button, CircularProgress, Snackbar, Tooltip } from "@mui/material";
import { createApiClient } from "api/apiClient";
import { useQuery } from "react-query";
import "./PolicyUploader.css";

interface PolicyUploaderProps {
  readonly fullOrgData: any;
}

export const PolicyUploader: React.FC<PolicyUploaderProps> = ({
  fullOrgData,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [byteString, setByteString] = useState<string>("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [isPdfVisible, setIsPdfVisible] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [fileDisplayName, setFileDisplayName] =
    useState<string>("No file selected");
  const accessToken = localStorage.getItem("super_admin_access_token");
  const apiClient = createApiClient(accessToken!);

  const handleSnackbarOpen = () => setIsSnackbarOpen(true);
  const handleSnackbarClose = () => setIsSnackbarOpen(false);
  const [timestamp, setTimestamp] = useState(Date.now());

  const {
    isLoading: isLoadingUploadOrgPrivacyPolicy,
    data: UploadOrgPrivacyPolicy,
    error: isUploadOrgPrivacyPolicyError,
    isFetching: isFetchingUploadOrgPrivacyPolicy,
    refetch: refetchUploadOrgPrivacyPolicy,
  } = useQuery(
    "uploadOrgPrivacyPolicy",
    async () => {
      const data = await apiClient.postUploadOrgFiles(
        byteString,
        fullOrgData.id,
        "policy"
      );
      setSnackbarMessage("Privacy Policy Uploaded Successfully!!");
      handleSnackbarOpen();
      return data;
    },
    {
      enabled: false,
    }
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsDisabled(false);
      setFileDisplayName(file.name);
      fullOrgData.policy_path = null;
    }
    setSelectedFile(file || null);
  };

  useEffect(() => {
    if (selectedFile) {
      renderPolicy();
    }
  }, [selectedFile]);

  useEffect(() => {
    setTimestamp(Date.now());
  }, []);

  const renderPolicy = () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = event.target?.result;
        if (typeof result === "string") {
          const byteString = result.split(",")[1];
          setByteString(byteString);
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  function uploadPolicy() {
    refetchUploadOrgPrivacyPolicy();
  }

  const togglePdfVisibility = () => {
    setIsPdfVisible((prevVisibility) => !prevVisibility);
  };

  return (
    <div className="container">
      <div className="top_container">
        <label htmlFor="fileInput">
          <input
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <Button variant="contained" color="primary" component="span">
            Choose File
          </Button>
        </label>
        <span className="fileName">{fileDisplayName}</span>
        {fullOrgData?.policy_path != null && (
          <Tooltip title={isPdfVisible ? "Hide PDF" : "Show PDF"}>
            <Button
              variant="contained"
              color="primary"
              onClick={togglePdfVisibility}
              startIcon={
                isPdfVisible ? <VisibilityIcon /> : <VisibilityOffIcon />
              }
            >
              {isPdfVisible ? "Hide Privacy Policy" : "Show Privacy Policy"}
            </Button>
          </Tooltip>
        )}
      </div>

      {isPdfVisible && fullOrgData?.policy_path != null && (
        <div className="pdf_container">
          <iframe
            src={
              byteString != ""
                ? `data:application/pdf;base64,${byteString}`
                : process.env["REACT_APP_ASSET_ENDPOINT"] +
                  fullOrgData?.policy_path +
                  "?timestamp=" +
                  timestamp
            }
            title="PDF Viewer"
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </div>
      )}

      {isLoadingUploadOrgPrivacyPolicy && (
        <div className="loader">
          <CircularProgress size={40} />
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={uploadPolicy}
        className="buttons"
        disabled={isDisabled}
      >
        Upload
      </Button>
    </div>
  );
};
