import React, { createContext, useState } from "react";

import { UserData } from "api/SharedTypes";

export type UserContextState = {
  readonly userData: UserData | undefined;
  readonly setLoggedInUserData: (userData: UserData) => void;
};

const initialValue: UserContextState = {
  userData: undefined,
  setLoggedInUserData: () => null,
};
export const UserContext = createContext<UserContextState>(initialValue);
UserContext.displayName = "UserContext";

export const UserProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [userData, setUserData] = useState<UserData | undefined>(undefined);

  const setLoggedInUserData = (userData: UserData) => setUserData(userData);

  return (
    <UserContext.Provider
      value={{
        userData,
        setLoggedInUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
