import React, { useState, useEffect, useRef } from "react";

import { Button, CircularProgress, Snackbar } from "@mui/material";
import { createApiClient } from "api/apiClient";
import { useQuery } from "react-query";
import "./LogoUploader.css";

interface LogoUploaderProps {
  readonly fullOrgData: any;
}

export const LogoUploader: React.FC<LogoUploaderProps> = ({ fullOrgData }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [byteString, setByteString] = useState<string>("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const accessToken = localStorage.getItem("super_admin_access_token");
  const apiClient = createApiClient(accessToken!);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const handleSnackbarOpen = () => setIsSnackbarOpen(true);
  const handleSnackbarClose = () => setIsSnackbarOpen(false);

  const {
    isLoading: isLoadingUploadOrgLogo,
    data: UploadOrgLogo,
    error: isUploadOrgLogoError,
    isFetching: isFetchingUploadOrgxyPolicy,
    refetch: refetchUploadOrgLogo,
  } = useQuery(
    "uploadOrgLogo",
    async () => {
      const data = await apiClient.postUploadOrgFiles(
        byteString,
        fullOrgData.id,
        "logo"
      );
      setSnackbarMessage("Logo Uploaded Successfully!!");
      handleSnackbarOpen();
      return data;
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (selectedFile) {
      renderPolicy();
    }
  }, [selectedFile]);

  useEffect(() => {
    setTimestamp(Date.now());
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsDisabled(false);
      setSelectedFile(file);
    }
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleChooseFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const renderPolicy = () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = event.target?.result;
        if (typeof result === "string") {
          const byteString = result.split(",")[1];
          setByteString(byteString);
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleUpload = () => {
    refetchUploadOrgLogo();
  };

  return (
    <div className="container">
      <div className="image_container" onClick={handleChooseFileClick}>
        {byteString != "" || fullOrgData?.logo != null ? (
          <img
            src={
              byteString != ""
                ? `data:image/jpg;base64,${byteString}`
                : process.env["REACT_APP_ASSET_ENDPOINT"] +
                  fullOrgData?.logo +
                  "?timestamp=" +
                  timestamp
            }
            alt=""
            style={{ width: "90%", height: "90%" }}
          />
        ) : (
          <h2>Select a Logo</h2>
        )}
      </div>
      {isLoadingUploadOrgLogo && (
        <div className="loader">
          <CircularProgress size={40} />
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isSnackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
      <input
        type="file"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".jpg"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        className="buttons"
        disabled={isDisabled}
      >
        Upload
      </Button>
    </div>
  );
};
