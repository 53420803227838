import React, { useState, useEffect } from "react";

import { Button, Checkbox, CircularProgress, Snackbar } from "@mui/material";
import { createApiClient } from "api/apiClient";
import I18nKey from "lib/I18nKeys";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import "./VBC.css";

interface VBCProps {
  readonly fullOrgData: any;
}

export const VBC: React.FC<VBCProps> = ({ fullOrgData }) => {
  const intl = useIntl();
  const accessToken = localStorage.getItem("super_admin_access_token");
  const apiClient = createApiClient(accessToken!);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const handleSnackbarOpen = () => setIsSnackbarOpen(true);
  const handleSnackbarClose = () => setIsSnackbarOpen(false);

  const {
    isLoading: isLoadingVbcProgram,
    data: vbcProgram,
    error: isVbcProgramError,
    isFetching: isFetchingVbcProgram,
    refetch: refetchVbcProgram,
  } = useQuery(
    "vbcProgram",
    async () => {
      const data = await apiClient.getVBCPrograms(fullOrgData.id);
      return data;
    },
    {
      enabled: true,
    }
  );

  const {
    isLoading: isLoadingPostVbcProgram,
    data: PostVbcProgram,
    error: isPostVbcProgramError,
    isFetching: isFetchingPostVbcProgram,
    refetch: refetchPostVbcProgram,
  } = useQuery(
    "PostVbcProgram",
    async () => {
      const data = await apiClient.postVBCList(fullOrgData.id, checkedLabelIds);
      setSnackbarMessage("VBC Updated Successfully!!");
      handleSnackbarOpen();
      return data;
    },
    {
      enabled: false,
    }
  );

  const [checkedLabelIds, setCheckedLabelIds] = useState<number[]>([]);

  useEffect(() => {
    if (vbcProgram) {
      const initialCheckedLabelIds = vbcProgram
        .filter((program: any) => program.org_vbc_id != null)
        .map((program: any) => program.vbc_id);
      setCheckedLabelIds(initialCheckedLabelIds);
    }
  }, [vbcProgram]);

  const handleCheckboxChange = (labelId: number) => {
    setCheckedLabelIds((prevCheckedLabelIds) => {
      if (prevCheckedLabelIds.includes(labelId)) {
        return prevCheckedLabelIds.filter((vbc_id) => vbc_id !== labelId);
      } else {
        return [...prevCheckedLabelIds, labelId];
      }
    });
  };

  const handleSave = () => {
    refetchPostVbcProgram();
  };

  const renderCheckboxes = () => {
    if (!vbcProgram) return null;
    const sortedPrograms = vbcProgram
      ?.slice()
      .sort((a: any, b: any) => a.vbc_id - b.vbc_id);

    return sortedPrograms?.map((program: any) => (
      <div key={program.vbc_id}>
        <Checkbox
          checked={checkedLabelIds.includes(program.vbc_id)}
          onChange={() => handleCheckboxChange(program.vbc_id)}
          color="primary"
          inputProps={{ "aria-label": program.name }}
        />
        {program.name}
      </div>
    ));
  };

  return (
    <div>
      {renderCheckboxes()}
      <div className="centerLoading">
        {isLoadingPostVbcProgram && (
          <div className="loader">
            <CircularProgress size={40} />
          </div>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={isSnackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
        <Button
          variant="contained"
          onClick={handleSave}
          color="inherit"
          sx={{ backgroundColor: "white", color: "#106a9c" }}
          disabled={checkedLabelIds.length === 0}
        >
          {intl.formatMessage({ id: I18nKey.UPDATE })}
        </Button>
      </div>
    </div>
  );
};
