import React from "react";

import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from "@mui/material";
import { FormFieldObject } from "components/UserForm";
import { UserFormFields } from "components/UserFormFields";
import I18nKey from "lib/I18nKeys";
import { useIntl } from "react-intl";
import { FormFieldType } from "services/Utils";
import { getIsDOBInvalid, validateEmail } from "services/utilsFunctions";

interface SuperAdminAddOrgDialogProps {
  readonly dialogTitle: string;
  readonly isOpen: boolean;
  readonly handleClose: () => void;
  readonly formFields: FormFieldObject;
  readonly handleDialogAction: () => void;
  readonly actionButtonText: string;
  readonly isLoading: boolean;
  readonly isGettingCustAdmin?: boolean;
  readonly physicalConsent: boolean;
  readonly physicalConsentHandler: (flag: boolean) => void;
  readonly showDeviceAssigment?: ((flag: any) => void) | undefined;
}

export const SuperAdminActionDialog: React.FC<SuperAdminAddOrgDialogProps> = ({
  dialogTitle,
  isOpen,
  handleDialogAction,
  handleClose,
  formFields,
  actionButtonText,
  isLoading,
  isGettingCustAdmin,
  physicalConsent,
  physicalConsentHandler,
  showDeviceAssigment,
}) => {
  const intl = useIntl();

  const isSubmitDisabled = () => {
    const requiredFields = Object.keys(formFields).filter(
      (field) => formFields[field].isRequired
    );
    const isRequiredFilled = requiredFields.map((field) => {
      if (formFields[field].type === FormFieldType.date) {
        if (!formFields[field].dateValue) {
          return true;
        }
        if (
          formFields[field].dateValue &&
          formFields[field].dateValue?.invalidReason
        ) {
          return true;
        }
        return false;
      }
      if (field === "email") {
        if (formFields[field].value) {
          if (validateEmail(formFields[field].value || "")) {
            return false;
          }
        }
        return true;
      }
      return formFields[field].value ? false : true;
    });
    const isDOBRequired = formFields["dob"]?.isRequired || false;
    const isDOBInvalid = getIsDOBInvalid(
      formFields["dob"]?.dateValue,
      isDOBRequired
    );
    return (
      isRequiredFilled.reduce((acc, nextValue) => acc || nextValue) ||
      isLoading ||
      isDOBInvalid
    );
  };

  let showConsentCheckbox = true;

  if (dialogTitle === "Add Customer Admin") {
    showConsentCheckbox = false;
  }

  if (dialogTitle === "Edit Customer Admin") {
    showConsentCheckbox = false;
  }

  if (dialogTitle === "Edit Customer Admin" && showDeviceAssigment) {
    showDeviceAssigment(true);
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        {isGettingCustAdmin ? (
          <div className="centerLoading">
            <CircularProgress />
          </div>
        ) : (
          <>
            <UserFormFields formFields={formFields} />
            {showConsentCheckbox && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  marginTop: "2rem",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        checked={physicalConsent}
                        onClick={() => physicalConsentHandler(!physicalConsent)}
                      />
                    }
                    label="Accept Physical Consent"
                    style={{
                      marginTop: "0.5rem",
                    }}
                  />
                </FormGroup>
                <Tooltip
                  title="If org accept physical consents patient will be visible to Care
                Team"
                  placement="top"
                >
                  <InfoSharpIcon color="info" />
                </Tooltip>
              </Box>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={isSubmitDisabled()}
          onClick={handleDialogAction}
        >
          {isLoading ? <CircularProgress size={30} /> : actionButtonText}
        </Button>
        <Button onClick={handleClose}>
          {intl.formatMessage({ id: I18nKey.CANCEL })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
